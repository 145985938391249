

import * as React from 'react'
import  {useEffect, useLayoutEffect, useState} from 'react'

import {useTranslation} from 'react-i18next'
import useGlobalContext from '../../containerContext/storeContext'

import './LoginForm.css'
import '../AccountVerify/index.css'
import '../confirmInfo/index.scss'

import LoginWrapper from './LoginWrapper.js'
import FormLogin from './FormLogin'
import ControlPanel from '../ControlPanel/controlPanel'
import {SnowLake} from "./snow";

function loginFromKeyboard() {
  try {
    const buttonConfirm = document.getElementsByClassName('button-confirm')
    buttonConfirm[0].click()
  } catch (error) {
    console.error(
      `================ error at loginFromKeyboard==================${error}`,
    )
  }
}
const listener = event => {
  if (event.code === 'Enter' || event.code === 'NumpadEnter') {
    loginFromKeyboard()
  }
}

export default function Login() {
  const [state, dispatch] = useGlobalContext()

  const {t} = useTranslation()
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  const [width, height] = size

  useEffect(() => {
    document.title = "Phần mềm quản lý bán hàng Online"
    document.addEventListener('keydown', listener)
    return () => document.removeEventListener('keydown', listener)
  }, [])
  if (state && state.isLogin) {
    return <ControlPanel />
  }

  // const [showForgotPasswordForm, setShowForgotPasswordForm] = useState(false)
  // const [showLoginForm, setShowLoginForm] = useState(true)

  return (
    <LoginWrapper>
      <SnowLake/>
      <div
        className={width < 1200 ? 'login-form mini-login-form' : 'login-form'}
      >
        {' '}
        <FormLogin />
      </div>
    </LoginWrapper>
  )
}
