import ButtonSetting from '../BtnSetting/buttonSetting'
import Account from '../Account'
import UserInformation from '../UserInformation'
import cls from 'clsx'
import css from './index.module.scss'
import * as React from 'react'
import useGlobalContext from '../../containerContext/storeContext'
import FeedBack from '../FeedBack'
import styled from 'styled-components'
import { LanguageSelect } from '../Language'
import { ChatJnt } from '../fixedActionBtnGroup/chatJnt'
import Notification from '../notiComp/notification'
import { Text } from '../../common/text'
import { useTranslation } from 'react-i18next'
import { PATH } from '../../const/path'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../common/button'
import BasicPopover from './popper'

export default function Header () {
  const [state] = useGlobalContext()
  const { t } = useTranslation()
  const [globalState, globalDispatch] = useGlobalContext()
  const { shouldMinimizeSidebar } = globalState
  const nav = useNavigate()
  const itemDropdown = {
    id: 3,
    name: 'other_actions',
    appearance: 'secondary',
    // icon: DELIVERY_ICONS.filePlus,
    type: 'dropdown',
    items: [
      { name: 'create_product', url: PATH.CREATE_PRODUCT, feature: 'create_product', action: 'view' },
      { name: 'create_customer', url: PATH.CREATE_CUSTOMER, feature: 'create_product', action: 'view' }
    ],
    className: 'delivery-management-table__selected-action-dropdown'
  }

  const bulkOrderLocation = [
    PATH.BULK_ORDER_CREATE.substring(0,12),
    PATH.BULK_ORDER_DEDUCT_CREATE.substring(0,12)
  ].includes(location.pathname.substring(0,12))
  return state.isLogin ? (
    <StyleHeader className={cls(css.wrapper)}>
      <div className={cls(css.left)}>
        <StyleHeader shouldMinimizeSidebar={shouldMinimizeSidebar}>
          <img className={'logo-tet'} src="/img/noel/hoamai.png" alt="logo"/>
          <div className={'header-left'}>
            <Text className={'header-left__item'}
                  onClick={() => nav(PATH.BULK_ORDER_CREATE)}
                  fontWeight={500}
                  data-active={bulkOrderLocation}>
              {t('batch_order')}</Text>
            <Text className={'header-left__item'}
                  onClick={() => nav(PATH.DELIVERY_MANAGEMENT)}
                  fontWeight={500}
                  data-active={location.pathname === PATH.DELIVERY_MANAGEMENT}
            >{t('order_delivery')}</Text>
            <Text className={'header-left__item'}
                  onClick={() => nav(PATH.TOOL_SHIPPING_TRACKING)}
                  fontWeight={500}
                  data-active={location.pathname === PATH.TOOL_SHIPPING_TRACKING}>{t('track_orders')}</Text>
            <Text className={'header-left__item'}
                  fontWeight={500}
                  onClick={() => nav(PATH.PRINT_TEMPLATE_SETTING)}
                  data-active={location.pathname === PATH.PRINT_TEMPLATE_SETTING}>{t('print_template_setup')}</Text>

            <Text as={'div'} className={'header-left__item'}>
              <Button className={'header-left__item-btn_left'}
                      type={'lg'}
                      onClick={() => nav(PATH.ORDER_CREATE)}
                      icon={<svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M16.6673 11.2719H11.1118V16.8275H8.88954V11.2719H3.33398V9.0497H8.88954V3.49414H11.1118V9.0497H16.6673V11.2719Z"
                          fill="white"/>
                      </svg>
                      }>{t('create_order')}</Button>
              <BasicPopover item={itemDropdown}/>
            </Text>

          </div>
        </StyleHeader>
      </div>
      {state.user.package.is_update_address != '0' &&
      <div className={cls(css.right)}>
        <div style={{
          display: 'flex', alignItems: 'center',
          borderRadius: '4px',
          border: +state.user.is_first_login === 1 ? '1px dashed var(--sematic-preparing, #FF9F41)' : 'none',
          padding: +state.user.is_first_login === 1 ? '2.5px 0' : '',
          marginRight: +state.user.is_first_login === 1 ? '8px' : ''
        }}>
          <div className={'guide-screen-boder-header04'} style={{marginRight: '16px'}}>
            <Notification/>
          </div>
          <ButtonSetting/>
          <LanguageSelect/>
        </div>
        <div style={{
          display: 'flex', alignItems: 'center',
          borderRadius: '4px',
          border: +state.user.is_first_login === 1 ? '1px dashed var(--sematic-preparing, #FF9F41)' : 'none'
        }}>
          <UserInformation/>
        </div>
        <FeedBack/>
        <ChatJnt/>
      </div>
      } </StyleHeader>
  ) : null
}
const StyleHeader = styled.div`
  .logo-tet{
    height: 100%;
    position: absolute;
    right: 200px;
    top: 0;
    transform: rotate(180deg);
  }
  .header-left{
    height: 66px;
    width: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    left: ${(prop) => !!prop?.shouldMinimizeSidebar ? '56px' : '220px'};
    top: 0px;
    padding-left: 32px;
    &__item{
      height: 100%;
      display: flex;
      align-items: center;
      margin-right: 32px;
      font-size: 16px!important;
      cursor: pointer;
      &:hover{
        color: #E5101D!important;
      }
      &[data-active=true]{
        font-weight: 700!important;
        color: #E5101D!important;
        border-bottom: #E5101D 2px solid;
      }
      &-btn_left{
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      &-btn_right{
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding: 0 8px;
        margin-left: 1px;
        display: flex;
        align-items: center;
      }
    }
  }
.guide-screen-boder-header04{
    cursor: pointer;
    height: 32px;
    padding-top: 4px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    &:hover{
       background: rgba(255, 66, 78, 0.1);
       .notice-component-popover {
           .popper-notice__toggle  {
             svg {
              color: #E5101D !important;
    
              path {
                fill: #E5101D;
              }
            }
          }
       }
    }
  }
`