import styled from 'styled-components'

export const SnowLake = _ => {
  return (
    <Styled>
      <div className="snowflakes" aria-hidden="true">
        <div className="snowflake">
          {ICON.mai_01}
        </div>
        <div className="snowflake">
          {ICON.dao_02}
        </div>
        <div className="snowflake">
          {ICON.mai_03}
        </div>
        <div className="snowflake">
          {ICON.dao_03}
        </div>
        <div className="snowflake">
          {ICON.dao_01}
        </div>
        <div className="snowflake">
          {ICON.mai_02}
        </div>
        <div className="snowflake">
          {ICON.mai_04}
        </div>
        <div className="snowflake">
          {ICON.dao_02}
        </div>
        <div className="snowflake">
          {ICON.mai_02}
        </div>
        <div className="snowflake">
          {ICON.dao_04}
        </div>
      </div>
       {/*<div className="snowflakes" aria-hidden="true">*/}
       {/*   <div className="snowflake">*/}
       {/*      ❅*/}
       {/*   </div>*/}
       {/*   <div className="snowflake">*/}
       {/*      ❅*/}
       {/*   </div>*/}
       {/*   <div className="snowflake">*/}
       {/*      ❆*/}
       {/*   </div>*/}
       {/*   <div className="snowflake">*/}
       {/*      ❄*/}
       {/*   </div>*/}
       {/*   <div className="snowflake">*/}
       {/*      ❅*/}
       {/*   </div>*/}
       {/*   <div className="snowflake">*/}
       {/*      ❆*/}
       {/*   </div>*/}
       {/*   <div className="snowflake">*/}
       {/*      ❄*/}
       {/*   </div>*/}
       {/*   <div className="snowflake">*/}
       {/*      ❅*/}
       {/*   </div>*/}
       {/*   <div className="snowflake">*/}
       {/*      ❆*/}
       {/*   </div>*/}
       {/*   <div className="snowflake">*/}
       {/*      ❄*/}
       {/*   </div>*/}
       {/*</div>*/}
    </Styled>
  )
}

const ICON = {
  mai_01: (
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3643 5.62347C13.3643 4.07531 12.1121 2.80035 10.5412 2.80035C10.1769 2.80035 9.81261 2.86865 9.49387 3.00525C9.49387 2.93695 9.49387 2.86865 9.49387 2.80035C9.49387 1.27496 8.24168 0 6.69352 0C5.14536 0 3.8704 1.25219 3.8704 2.82312C3.8704 2.89142 3.8704 2.95972 3.8704 3.02802C3.55166 2.89142 3.18739 2.82312 2.82312 2.82312C1.27496 2.82312 0 4.07531 0 5.64623C0 6.96673 0.910683 8.08231 2.14011 8.37828C1.7303 8.85639 1.47986 9.49387 1.47986 10.1769C1.47986 11.725 2.73205 13 4.30298 13C5.30473 13 6.19264 12.4764 6.69352 11.6795C7.1944 12.4764 8.08231 13 9.08406 13C10.6322 13 11.9072 11.7478 11.9072 10.1769C11.9072 9.49387 11.6567 8.85639 11.2469 8.37828C12.4536 8.05955 13.3643 6.94396 13.3643 5.62347Z"
        fill="#FFDF6C"/>
      <path d="M6.7616 4.35059H6.625V9.33658H6.7616V4.35059Z" fill="#FF424E"/>
      <path
        d="M6.69234 4.78312C6.93124 4.78312 7.12491 4.58945 7.12491 4.35054C7.12491 4.11164 6.93124 3.91797 6.69234 3.91797C6.45344 3.91797 6.25977 4.11164 6.25977 4.35054C6.25977 4.58945 6.45344 4.78312 6.69234 4.78312Z"
        fill="#FF424E"/>
      <path
        d="M6.69234 9.76945C6.93124 9.76945 7.12491 9.57578 7.12491 9.33687C7.12491 9.09797 6.93124 8.9043 6.69234 8.9043C6.45344 8.9043 6.25977 9.09797 6.25977 9.33687C6.25977 9.57578 6.45344 9.76945 6.69234 9.76945Z"
        fill="#FF424E"/>
      <path d="M4.9794 5.02646L4.88281 5.12305L8.40841 8.64864L8.505 8.55205L4.9794 5.02646Z" fill="#FF424E"/>
      <path
        d="M4.91793 5.51066C5.15683 5.51066 5.3505 5.31699 5.3505 5.07808C5.3505 4.83918 5.15683 4.64551 4.91793 4.64551C4.67902 4.64551 4.48535 4.83918 4.48535 5.07808C4.48535 5.31699 4.67902 5.51066 4.91793 5.51066Z"
        fill="#FF424E"/>
      <path
        d="M8.44625 9.03898C8.68515 9.03898 8.87882 8.84531 8.87882 8.6064C8.87882 8.3675 8.68515 8.17383 8.44625 8.17383C8.20734 8.17383 8.01367 8.3675 8.01367 8.6064C8.01367 8.84531 8.20734 9.03898 8.44625 9.03898Z"
        fill="#FF424E"/>
      <path d="M9.17447 6.76367H4.18848V6.90027H9.17447V6.76367Z" fill="#FF424E"/>
      <path
        d="M4.18843 7.26359C4.42734 7.26359 4.62101 7.06992 4.62101 6.83101C4.62101 6.59211 4.42734 6.39844 4.18843 6.39844C3.94953 6.39844 3.75586 6.59211 3.75586 6.83101C3.75586 7.06992 3.94953 7.26359 4.18843 7.26359Z"
        fill="#FF424E"/>
      <path
        d="M9.17574 7.26359C9.41464 7.26359 9.60831 7.06992 9.60831 6.83101C9.60831 6.59211 9.41464 6.39844 9.17574 6.39844C8.93683 6.39844 8.74316 6.59211 8.74316 6.83101C8.74316 7.06992 8.93683 7.26359 9.17574 7.26359Z"
        fill="#FF424E"/>
      <path d="M4.87216 8.54403L4.96875 8.64062L8.49434 5.11503L8.39775 5.01844L4.87216 8.54403Z" fill="#FF424E"/>
      <path
        d="M4.91793 9.03898C5.15683 9.03898 5.3505 8.84531 5.3505 8.6064C5.3505 8.3675 5.15683 8.17383 4.91793 8.17383C4.67902 8.17383 4.48535 8.3675 4.48535 8.6064C4.48535 8.84531 4.67902 9.03898 4.91793 9.03898Z"
        fill="#FF424E"/>
      <path
        d="M8.44625 5.51066C8.68515 5.51066 8.87882 5.31699 8.87882 5.07808C8.87882 4.83918 8.68515 4.64551 8.44625 4.64551C8.20734 4.64551 8.01367 4.83918 8.01367 5.07808C8.01367 5.31699 8.20734 5.51066 8.44625 5.51066Z"
        fill="#FF424E"/>
    </svg>
  ),
  mai_02: (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2083 7.06296C14.2083 5.63389 13.0525 4.45701 11.6024 4.45701C11.2661 4.45701 10.9299 4.52006 10.6356 4.64615C10.6356 4.5831 10.6356 4.52006 10.6356 4.45701C10.6356 3.04895 9.47978 1.87207 8.0507 1.87207C6.62163 1.87207 5.44475 3.02794 5.44475 4.47802C5.44475 4.54107 5.44475 4.60412 5.44475 4.66717C5.15053 4.54107 4.81428 4.47802 4.47802 4.47802C3.04895 4.47802 1.87207 5.63389 1.87207 7.08398C1.87207 8.30289 2.7127 9.33267 3.84755 9.60587C3.46927 10.0472 3.23809 10.6356 3.23809 11.2661C3.23809 12.6952 4.39396 13.8721 5.84405 13.8721C6.76874 13.8721 7.58836 13.3887 8.0507 12.6532C8.51305 13.3887 9.33267 13.8721 10.2574 13.8721C11.6864 13.8721 12.8633 12.7162 12.8633 11.2661C12.8633 10.6356 12.6321 10.0472 12.2539 9.60587C13.3677 9.31165 14.2083 8.28188 14.2083 7.06296Z"
        stroke="#FFDF6C" stroke-width="1.35265" stroke-miterlimit="10"/>
      <path d="M8.1134 5.88672H7.9873V10.4892H8.1134V5.88672Z" fill="#FFDF6C"/>
      <path
        d="M8.04969 6.2859C8.27022 6.2859 8.44899 6.10713 8.44899 5.8866C8.44899 5.66608 8.27022 5.4873 8.04969 5.4873C7.82916 5.4873 7.65039 5.66608 7.65039 5.8866C7.65039 6.10713 7.82916 6.2859 8.04969 6.2859Z"
        fill="#FFDF6C"/>
      <path
        d="M8.04969 10.8884C8.27022 10.8884 8.44899 10.7097 8.44899 10.4891C8.44899 10.2686 8.27022 10.0898 8.04969 10.0898C7.82916 10.0898 7.65039 10.2686 7.65039 10.4891C7.65039 10.7097 7.82916 10.8884 8.04969 10.8884Z"
        fill="#FFDF6C"/>
      <path d="M6.46807 6.51045L6.37891 6.59961L9.6333 9.854L9.72246 9.76484L6.46807 6.51045Z" fill="#FFDF6C"/>
      <path
        d="M6.41102 6.95778C6.63155 6.95778 6.81032 6.77901 6.81032 6.55848C6.81032 6.33795 6.63155 6.15918 6.41102 6.15918C6.19049 6.15918 6.01172 6.33795 6.01172 6.55848C6.01172 6.77901 6.19049 6.95778 6.41102 6.95778Z"
        fill="#FFDF6C"/>
      <path
        d="M9.66785 10.2146C9.88838 10.2146 10.0672 10.0358 10.0672 9.81531C10.0672 9.59479 9.88838 9.41602 9.66785 9.41602C9.44733 9.41602 9.26855 9.59479 9.26855 9.81531C9.26855 10.0358 9.44733 10.2146 9.66785 10.2146Z"
        fill="#FFDF6C"/>
      <path d="M10.3407 8.11426H5.73828V8.24035H10.3407V8.11426Z" fill="#FFDF6C"/>
      <path
        d="M5.73817 8.57594C5.95869 8.57594 6.13747 8.39717 6.13747 8.17664C6.13747 7.95612 5.95869 7.77734 5.73817 7.77734C5.51764 7.77734 5.33887 7.95612 5.33887 8.17664C5.33887 8.39717 5.51764 8.57594 5.73817 8.57594Z"
        fill="#FFDF6C"/>
      <path
        d="M10.3417 8.57594C10.5622 8.57594 10.741 8.39717 10.741 8.17664C10.741 7.95612 10.5622 7.77734 10.3417 7.77734C10.1212 7.77734 9.94238 7.95612 9.94238 8.17664C9.94238 8.39717 10.1212 8.57594 10.3417 8.57594Z"
        fill="#FFDF6C"/>
      <path d="M6.36885 9.75752L6.45801 9.84668L9.7124 6.59229L9.62324 6.50312L6.36885 9.75752Z" fill="#FFDF6C"/>
      <path
        d="M6.41102 10.2146C6.63155 10.2146 6.81032 10.0358 6.81032 9.81531C6.81032 9.59479 6.63155 9.41602 6.41102 9.41602C6.19049 9.41602 6.01172 9.59479 6.01172 9.81531C6.01172 10.0358 6.19049 10.2146 6.41102 10.2146Z"
        fill="#FFDF6C"/>
      <path
        d="M9.66785 6.95778C9.88838 6.95778 10.0672 6.77901 10.0672 6.55848C10.0672 6.33795 9.88838 6.15918 9.66785 6.15918C9.44733 6.15918 9.26855 6.33795 9.26855 6.55848C9.26855 6.77901 9.44733 6.95778 9.66785 6.95778Z"
        fill="#FFDF6C"/>
    </svg>
  ),
  mai_03: (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.13674 1.74121H5.90332V10.2611H6.13674V1.74121Z" fill="#FFDF6C"/>
      <path
        d="M6.01828 2.47797C6.42641 2.47797 6.75726 2.14711 6.75726 1.73898C6.75726 1.33085 6.42641 1 6.01828 1C5.61015 1 5.2793 1.33085 5.2793 1.73898C5.2793 2.14711 5.61015 2.47797 6.01828 2.47797Z"
        fill="#FFDF6C"/>
      <path
        d="M6.01828 10.9975C6.42641 10.9975 6.75726 10.6666 6.75726 10.2585C6.75726 9.85039 6.42641 9.51953 6.01828 9.51953C5.61015 9.51953 5.2793 9.85039 5.2793 10.2585C5.2793 10.6666 5.61015 10.9975 6.01828 10.9975Z"
        fill="#FFDF6C"/>
      <path d="M3.09279 2.89354L2.92773 3.05859L8.95214 9.083L9.11719 8.91795L3.09279 2.89354Z" fill="#FFDF6C"/>
      <path
        d="M2.98605 3.72211C3.39418 3.72211 3.72504 3.39125 3.72504 2.98312C3.72504 2.57499 3.39418 2.24414 2.98605 2.24414C2.57792 2.24414 2.24707 2.57499 2.24707 2.98312C2.24707 3.39125 2.57792 3.72211 2.98605 3.72211Z"
        fill="#FFDF6C"/>
      <path
        d="M9.01633 9.75238C9.42446 9.75238 9.75531 9.42153 9.75531 9.0134C9.75531 8.60527 9.42446 8.27441 9.01633 8.27441C8.6082 8.27441 8.27734 8.60527 8.27734 9.0134C8.27734 9.42153 8.6082 9.75238 9.01633 9.75238Z"
        fill="#FFDF6C"/>
      <path d="M10.2591 5.86426H1.73926V6.09768H10.2591V5.86426Z" fill="#FFDF6C"/>
      <path
        d="M1.73898 6.7182C2.14711 6.7182 2.47797 6.38735 2.47797 5.97922C2.47797 5.57109 2.14711 5.24023 1.73898 5.24023C1.33085 5.24023 1 5.57109 1 5.97922C1 6.38735 1.33085 6.7182 1.73898 6.7182Z"
        fill="#FFDF6C"/>
      <path
        d="M10.2605 6.7182C10.6686 6.7182 10.9995 6.38735 10.9995 5.97922C10.9995 5.57109 10.6686 5.24023 10.2605 5.24023C9.85234 5.24023 9.52148 5.57109 9.52148 5.97922C9.52148 6.38735 9.85234 6.7182 10.2605 6.7182Z"
        fill="#FFDF6C"/>
      <path d="M2.90819 8.90428L3.07324 9.06934L9.09765 3.04493L8.9326 2.87988L2.90819 8.90428Z" fill="#FFDF6C"/>
      <path
        d="M2.98605 9.75238C3.39418 9.75238 3.72504 9.42153 3.72504 9.0134C3.72504 8.60527 3.39418 8.27441 2.98605 8.27441C2.57792 8.27441 2.24707 8.60527 2.24707 9.0134C2.24707 9.42153 2.57792 9.75238 2.98605 9.75238Z"
        fill="#FFDF6C"/>
      <path
        d="M9.01633 3.72211C9.42446 3.72211 9.75531 3.39125 9.75531 2.98312C9.75531 2.57499 9.42446 2.24414 9.01633 2.24414C8.6082 2.24414 8.27734 2.57499 8.27734 2.98312C8.27734 3.39125 8.6082 3.72211 9.01633 3.72211Z"
        fill="#FFDF6C"/>
    </svg>
  ),
  mai_04: (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.54614 12.3878C4.34341 12.2954 4.17758 12.1891 4.0602 12.0438C3.556 11.5384 3.43942 10.72 3.66645 9.75219C3.92814 8.70834 4.58637 7.60043 5.52558 6.68186C7.11553 5.14171 9.15859 4.42036 10.4003 4.98655C10.603 5.07899 10.7689 5.18521 10.8862 5.33056C11.3904 5.83595 11.507 6.65435 11.28 7.62217C11.0183 8.66601 10.3601 9.77393 9.42086 10.6925C7.8447 12.2695 5.78785 12.954 4.54614 12.3878Z"
        fill="#FFDF6C"/>
      <path
        d="M5.17873 6.36971C6.72022 4.86868 8.60191 4.16559 9.99384 4.40234C9.70432 3.62753 9.20946 3.03456 8.51592 2.81016C6.81098 2.1858 4.64226 3.92119 3.64328 6.6491C3.25711 7.6974 3.10834 8.76213 3.17871 9.68189C3.43817 8.57581 4.13106 7.39188 5.17873 6.36971Z"
        fill="#FFDF6C"/>
    </svg>
  ),
  dao_01: (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.3643 6.62347C14.3643 5.07531 13.1121 3.80035 11.5412 3.80035C11.1769 3.80035 10.8126 3.86865 10.4939 4.00525C10.4939 3.93695 10.4939 3.86865 10.4939 3.80035C10.4939 2.27496 9.24168 1 7.69352 1C6.14536 1 4.8704 2.25219 4.8704 3.82312C4.8704 3.89142 4.8704 3.95972 4.8704 4.02802C4.55166 3.89142 4.18739 3.82312 3.82312 3.82312C2.27496 3.82312 1 5.07531 1 6.64623C1 7.96673 1.91068 9.08231 3.14011 9.37828C2.7303 9.85639 2.47986 10.4939 2.47986 11.1769C2.47986 12.725 3.73205 14 5.30298 14C6.30473 14 7.19264 13.4764 7.69352 12.6795C8.1944 13.4764 9.08231 14 10.0841 14C11.6322 14 12.9072 12.7478 12.9072 11.1769C12.9072 10.4939 12.6567 9.85639 12.2469 9.37828C13.4536 9.05955 14.3643 7.94396 14.3643 6.62347Z"
        fill="#FFC4E0"/>
      <path d="M7.7616 5.35059H7.625V10.3366H7.7616V5.35059Z" fill="#FF1E2C"/>
      <path
        d="M7.69234 5.78312C7.93124 5.78312 8.12491 5.58945 8.12491 5.35054C8.12491 5.11164 7.93124 4.91797 7.69234 4.91797C7.45344 4.91797 7.25977 5.11164 7.25977 5.35054C7.25977 5.58945 7.45344 5.78312 7.69234 5.78312Z"
        fill="#FF1E2C"/>
      <path
        d="M7.69234 10.7694C7.93124 10.7694 8.12491 10.5758 8.12491 10.3369C8.12491 10.098 7.93124 9.9043 7.69234 9.9043C7.45344 9.9043 7.25977 10.098 7.25977 10.3369C7.25977 10.5758 7.45344 10.7694 7.69234 10.7694Z"
        fill="#FF1E2C"/>
      <path d="M5.9794 6.02646L5.88281 6.12305L9.40841 9.64864L9.505 9.55205L5.9794 6.02646Z" fill="#FF1E2C"/>
      <path
        d="M5.91793 6.51066C6.15683 6.51066 6.3505 6.31699 6.3505 6.07808C6.3505 5.83918 6.15683 5.64551 5.91793 5.64551C5.67902 5.64551 5.48535 5.83918 5.48535 6.07808C5.48535 6.31699 5.67902 6.51066 5.91793 6.51066Z"
        fill="#FF1E2C"/>
      <path
        d="M9.44625 10.039C9.68515 10.039 9.87882 9.84531 9.87882 9.6064C9.87882 9.3675 9.68515 9.17383 9.44625 9.17383C9.20734 9.17383 9.01367 9.3675 9.01367 9.6064C9.01367 9.84531 9.20734 10.039 9.44625 10.039Z"
        fill="#FF1E2C"/>
      <path d="M10.1745 7.76367H5.18848V7.90027H10.1745V7.76367Z" fill="#FF1E2C"/>
      <path
        d="M5.18843 8.26359C5.42734 8.26359 5.62101 8.06992 5.62101 7.83101C5.62101 7.59211 5.42734 7.39844 5.18843 7.39844C4.94953 7.39844 4.75586 7.59211 4.75586 7.83101C4.75586 8.06992 4.94953 8.26359 5.18843 8.26359Z"
        fill="#FF1E2C"/>
      <path
        d="M10.1757 8.26359C10.4146 8.26359 10.6083 8.06992 10.6083 7.83101C10.6083 7.59211 10.4146 7.39844 10.1757 7.39844C9.93683 7.39844 9.74316 7.59211 9.74316 7.83101C9.74316 8.06992 9.93683 8.26359 10.1757 8.26359Z"
        fill="#FF1E2C"/>
      <path d="M5.87216 9.54403L5.96875 9.64062L9.49434 6.11503L9.39775 6.01844L5.87216 9.54403Z" fill="#FF1E2C"/>
      <path
        d="M5.91793 10.039C6.15683 10.039 6.3505 9.84531 6.3505 9.6064C6.3505 9.3675 6.15683 9.17383 5.91793 9.17383C5.67902 9.17383 5.48535 9.3675 5.48535 9.6064C5.48535 9.84531 5.67902 10.039 5.91793 10.039Z"
        fill="#FF1E2C"/>
      <path
        d="M9.44625 6.51066C9.68515 6.51066 9.87882 6.31699 9.87882 6.07808C9.87882 5.83918 9.68515 5.64551 9.44625 5.64551C9.20734 5.64551 9.01367 5.83918 9.01367 6.07808C9.01367 6.31699 9.20734 6.51066 9.44625 6.51066Z"
        fill="#FF1E2C"/>
    </svg>
  ),
  dao_02: (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2083 7.06296C14.2083 5.63389 13.0525 4.45701 11.6024 4.45701C11.2661 4.45701 10.9299 4.52006 10.6356 4.64615C10.6356 4.5831 10.6356 4.52006 10.6356 4.45701C10.6356 3.04895 9.47978 1.87207 8.0507 1.87207C6.62163 1.87207 5.44475 3.02794 5.44475 4.47802C5.44475 4.54107 5.44475 4.60412 5.44475 4.66717C5.15053 4.54107 4.81428 4.47802 4.47802 4.47802C3.04895 4.47802 1.87207 5.63389 1.87207 7.08398C1.87207 8.30289 2.7127 9.33267 3.84755 9.60587C3.46927 10.0472 3.23809 10.6356 3.23809 11.2661C3.23809 12.6952 4.39396 13.8721 5.84405 13.8721C6.76874 13.8721 7.58836 13.3887 8.0507 12.6532C8.51305 13.3887 9.33267 13.8721 10.2574 13.8721C11.6864 13.8721 12.8633 12.7162 12.8633 11.2661C12.8633 10.6356 12.6321 10.0472 12.2539 9.60587C13.3677 9.31165 14.2083 8.28188 14.2083 7.06296Z"
        stroke="#FFC4E0" stroke-width="1.35265" stroke-miterlimit="10"/>
      <path d="M8.1134 5.88672H7.9873V10.4892H8.1134V5.88672Z" fill="#FFC4E0"/>
      <path
        d="M8.04969 6.2859C8.27022 6.2859 8.44899 6.10713 8.44899 5.8866C8.44899 5.66608 8.27022 5.4873 8.04969 5.4873C7.82916 5.4873 7.65039 5.66608 7.65039 5.8866C7.65039 6.10713 7.82916 6.2859 8.04969 6.2859Z"
        fill="#FFC4E0"/>
      <path
        d="M8.04969 10.8884C8.27022 10.8884 8.44899 10.7097 8.44899 10.4891C8.44899 10.2686 8.27022 10.0898 8.04969 10.0898C7.82916 10.0898 7.65039 10.2686 7.65039 10.4891C7.65039 10.7097 7.82916 10.8884 8.04969 10.8884Z"
        fill="#FFC4E0"/>
      <path d="M6.46807 6.51045L6.37891 6.59961L9.6333 9.854L9.72246 9.76484L6.46807 6.51045Z" fill="#FFC4E0"/>
      <path
        d="M6.41102 6.95778C6.63155 6.95778 6.81032 6.77901 6.81032 6.55848C6.81032 6.33795 6.63155 6.15918 6.41102 6.15918C6.19049 6.15918 6.01172 6.33795 6.01172 6.55848C6.01172 6.77901 6.19049 6.95778 6.41102 6.95778Z"
        fill="#FFC4E0"/>
      <path
        d="M9.66785 10.2146C9.88838 10.2146 10.0672 10.0358 10.0672 9.81531C10.0672 9.59479 9.88838 9.41602 9.66785 9.41602C9.44733 9.41602 9.26855 9.59479 9.26855 9.81531C9.26855 10.0358 9.44733 10.2146 9.66785 10.2146Z"
        fill="#FFC4E0"/>
      <path d="M10.3407 8.11426H5.73828V8.24035H10.3407V8.11426Z" fill="#FFC4E0"/>
      <path
        d="M5.73817 8.57594C5.95869 8.57594 6.13747 8.39717 6.13747 8.17664C6.13747 7.95612 5.95869 7.77734 5.73817 7.77734C5.51764 7.77734 5.33887 7.95612 5.33887 8.17664C5.33887 8.39717 5.51764 8.57594 5.73817 8.57594Z"
        fill="#FFC4E0"/>
      <path
        d="M10.3417 8.57594C10.5622 8.57594 10.741 8.39717 10.741 8.17664C10.741 7.95612 10.5622 7.77734 10.3417 7.77734C10.1212 7.77734 9.94238 7.95612 9.94238 8.17664C9.94238 8.39717 10.1212 8.57594 10.3417 8.57594Z"
        fill="#FFC4E0"/>
      <path d="M6.36885 9.75752L6.45801 9.84668L9.7124 6.59229L9.62324 6.50312L6.36885 9.75752Z" fill="#FFC4E0"/>
      <path
        d="M6.41102 10.2146C6.63155 10.2146 6.81032 10.0358 6.81032 9.81531C6.81032 9.59479 6.63155 9.41602 6.41102 9.41602C6.19049 9.41602 6.01172 9.59479 6.01172 9.81531C6.01172 10.0358 6.19049 10.2146 6.41102 10.2146Z"
        fill="#FFC4E0"/>
      <path
        d="M9.66785 6.95778C9.88838 6.95778 10.0672 6.77901 10.0672 6.55848C10.0672 6.33795 9.88838 6.15918 9.66785 6.15918C9.44733 6.15918 9.26855 6.33795 9.26855 6.55848C9.26855 6.77901 9.44733 6.95778 9.66785 6.95778Z"
        fill="#FFC4E0"/>
    </svg>
  ),
  dao_03: (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.13674 1.74121H5.90332V10.2611H6.13674V1.74121Z" fill="#FFC4E0"/>
      <path
        d="M6.01828 2.47797C6.42641 2.47797 6.75726 2.14711 6.75726 1.73898C6.75726 1.33085 6.42641 1 6.01828 1C5.61015 1 5.2793 1.33085 5.2793 1.73898C5.2793 2.14711 5.61015 2.47797 6.01828 2.47797Z"
        fill="#FFC4E0"/>
      <path
        d="M6.01828 10.9975C6.42641 10.9975 6.75726 10.6666 6.75726 10.2585C6.75726 9.85039 6.42641 9.51953 6.01828 9.51953C5.61015 9.51953 5.2793 9.85039 5.2793 10.2585C5.2793 10.6666 5.61015 10.9975 6.01828 10.9975Z"
        fill="#FFC4E0"/>
      <path d="M3.09279 2.89354L2.92773 3.05859L8.95214 9.083L9.11719 8.91795L3.09279 2.89354Z" fill="#FFC4E0"/>
      <path
        d="M2.98605 3.72211C3.39418 3.72211 3.72504 3.39125 3.72504 2.98312C3.72504 2.57499 3.39418 2.24414 2.98605 2.24414C2.57792 2.24414 2.24707 2.57499 2.24707 2.98312C2.24707 3.39125 2.57792 3.72211 2.98605 3.72211Z"
        fill="#FFC4E0"/>
      <path
        d="M9.01633 9.75238C9.42446 9.75238 9.75531 9.42153 9.75531 9.0134C9.75531 8.60527 9.42446 8.27441 9.01633 8.27441C8.6082 8.27441 8.27734 8.60527 8.27734 9.0134C8.27734 9.42153 8.6082 9.75238 9.01633 9.75238Z"
        fill="#FFC4E0"/>
      <path d="M10.2591 5.86426H1.73926V6.09768H10.2591V5.86426Z" fill="#FFC4E0"/>
      <path
        d="M1.73898 6.7182C2.14711 6.7182 2.47797 6.38735 2.47797 5.97922C2.47797 5.57109 2.14711 5.24023 1.73898 5.24023C1.33085 5.24023 1 5.57109 1 5.97922C1 6.38735 1.33085 6.7182 1.73898 6.7182Z"
        fill="#FFC4E0"/>
      <path
        d="M10.2605 6.7182C10.6686 6.7182 10.9995 6.38735 10.9995 5.97922C10.9995 5.57109 10.6686 5.24023 10.2605 5.24023C9.85234 5.24023 9.52148 5.57109 9.52148 5.97922C9.52148 6.38735 9.85234 6.7182 10.2605 6.7182Z"
        fill="#FFC4E0"/>
      <path d="M2.90819 8.90428L3.07324 9.06934L9.09765 3.04493L8.9326 2.87988L2.90819 8.90428Z" fill="#FFC4E0"/>
      <path
        d="M2.98605 9.75238C3.39418 9.75238 3.72504 9.42153 3.72504 9.0134C3.72504 8.60527 3.39418 8.27441 2.98605 8.27441C2.57792 8.27441 2.24707 8.60527 2.24707 9.0134C2.24707 9.42153 2.57792 9.75238 2.98605 9.75238Z"
        fill="#FFC4E0"/>
      <path
        d="M9.01633 3.72211C9.42446 3.72211 9.75531 3.39125 9.75531 2.98312C9.75531 2.57499 9.42446 2.24414 9.01633 2.24414C8.6082 2.24414 8.27734 2.57499 8.27734 2.98312C8.27734 3.39125 8.6082 3.72211 9.01633 3.72211Z"
        fill="#FFC4E0"/>
    </svg>
  ),
  dao_04: (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.54614 12.3878C4.34341 12.2954 4.17758 12.1891 4.0602 12.0438C3.556 11.5384 3.43942 10.72 3.66645 9.75219C3.92814 8.70834 4.58637 7.60043 5.52558 6.68186C7.11553 5.14171 9.15859 4.42036 10.4003 4.98655C10.603 5.07899 10.7689 5.18521 10.8862 5.33056C11.3904 5.83595 11.507 6.65435 11.28 7.62217C11.0183 8.66601 10.3601 9.77393 9.42086 10.6925C7.8447 12.2695 5.78785 12.954 4.54614 12.3878Z"
        fill="#FFC4E0"/>
      <path
        d="M5.17873 6.36971C6.72022 4.86868 8.60191 4.16559 9.99384 4.40234C9.70432 3.62753 9.20946 3.03456 8.51592 2.81016C6.81098 2.1858 4.64226 3.92119 3.64328 6.6491C3.25711 7.6974 3.10834 8.76213 3.17871 9.68189C3.43817 8.57581 4.13106 7.39188 5.17873 6.36971Z"
        fill="#FFC4E0"/>
    </svg>
  )
}

const Styled = styled.div`
.intro,
.intro a {
  color:#fff;
}
/* customizable snowflake styling */
.snowflake {
  color: #fff;
  font-size: 1em;
  font-family: Arial;
  text-shadow: 0 0 1px #000;
}

@-webkit-keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}
@-webkit-keyframes snowflakes-shake{
  0%{-webkit-transform:translateX(0px);transform:translateX(0px)}50%{-webkit-transform:translateX(80px);transform:translateX(80px)}100%{-webkit-transform:translateX(0px);transform:translateX(0px)}}@keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}@keyframes snowflakes-shake{0%{transform:translateX(0px)}50%{transform:translateX(80px)}100%{transform:translateX(0px)}}.snowflake{position:fixed;top:-10%;z-index:9999;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;cursor:default;-webkit-animation-name:snowflakes-fall,snowflakes-shake;-webkit-animation-duration:10s,3s;-webkit-animation-timing-function:linear,ease-in-out;-webkit-animation-iteration-count:infinite,infinite;-webkit-animation-play-state:running,running;animation-name:snowflakes-fall,snowflakes-shake;animation-duration:10s,3s;animation-timing-function:linear,ease-in-out;animation-iteration-count:infinite,infinite;animation-play-state:running,running}.snowflake:nth-of-type(0){left:1%;-webkit-animation-delay:0s,0s;animation-delay:0s,0s}.snowflake:nth-of-type(1){left:10%;-webkit-animation-delay:1s,1s;animation-delay:1s,1s}.snowflake:nth-of-type(2){left:20%;-webkit-animation-delay:6s,.5s;animation-delay:6s,.5s}.snowflake:nth-of-type(3){left:30%;-webkit-animation-delay:4s,2s;animation-delay:4s,2s}.snowflake:nth-of-type(4){left:40%;-webkit-animation-delay:2s,2s;animation-delay:2s,2s}.snowflake:nth-of-type(5){left:50%;-webkit-animation-delay:8s,3s;animation-delay:8s,3s}.snowflake:nth-of-type(6){left:60%;-webkit-animation-delay:6s,2s;animation-delay:6s,2s}.snowflake:nth-of-type(7){left:70%;-webkit-animation-delay:2.5s,1s;animation-delay:2.5s,1s}.snowflake:nth-of-type(8){left:80%;-webkit-animation-delay:1s,0s;animation-delay:1s,0s}.snowflake:nth-of-type(9){left:90%;-webkit-animation-delay:3s,1.5s;animation-delay:3s,1.5s}
`