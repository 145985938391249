// const envLive = location.host === 'evoshop.vn'
const envLive = false

export const PATH = {
  HOME: '/',
  NOT_FOUND: '/404',
  NO_CONNECTION: '/no-connection',
  ADMIN_DASHBOAR: '/admin',
  ACCOUNT_VERIFY: '/account-verify',
  UPDATE_PASSWORD: '/update-password',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  LOGIN_WARNING: '/login-warning',
  ORDER: '/orders',
  ORDER_CREATE: '/orders/create',
  ORDER_ORIGIN: '/order-origin',
  PRODUCT_MANAGEMENT: '/products',
  CREATE_PRODUCT: '/product/create',
  CONFIRM_INFO: '/confirm-info',
  CONFIRM_INFO_SOCIAL: '/social/confirm-info',
  EDIT_PRODUCT: '/product/edit/:productId/:totalVersion',
  PRINT_BARCODE_PRODUCT: '/product/print-barcode/:productIds',
  EDIT_ORDER: '/order/edit/:orderId',
  COPY_ORDER: '/order/copy/:orderId',
  CREATE_CATEGORY: '/category/create',
  SHOP_SETTING: '/setting/shop_setting',
  PACKAGE_SETTING: '/setting/shop_package',
  DELIVERY_SHIPMENTS: '/delivery/shipments',
  INVENTORY_REPORT: '/warehouse/inventory-report',
  STOCK_INVENTORY_REPORT: '/warehouse/stock-inventory-report',
  LOW_QUANTITY_REPORT: '/warehouse/low-quantity-report',
  TRANSFER_INVENTORY_REPORT: '/warehouse/transfer-inventory-report',
  SALES_REPORT: '/warehouse/sales-report',
  WAREHOUSE_MANAGEMENT: '/warehouse/management',
  WAREHOUSE_TRANSFER: '/warehouse/transfer',
  WAREHOUSE_PRODUCT: '/warehouse/product',
  INVENTORY_CONTROL: '/warehouse/check',
  WAREHOUSE_PRICE: '/warehouse/price',
  IMPORT_REPORT: '/warehouse/import-report',
  INVENTORY_CONTROL_EDIT_TICKET: '/warehouse/check/edit',
  INVENTORY_CONTROL_CREATE_TICKET: '/warehouse/check/create',
  INVENTORY_CONTROL_INFO_TICKET: '/warehouse/check/info',
  SUPPLIER: '/product/supplier',
  IMPORT: '/product/import',
  SUPPLIER_DETAILS: '/supplier/details',
  FACEBOOK_CONNECT: '/facebook',
  FACEBOOK_ORDERS: '/facebook/orders',
  FACEBOOK_RESPONSE_CONTENT_SCRIPT: '/facebook/response-content-scripts',
  FACEBOOK_HIDE_AUTO_COMMENT: '/facebook/hide-auto-comments',
  FACEBOOK_AUTO_RESPONSES: '/facebook/auto-responses',
  FACEBOOK_AUTO_MESSAGES: '/facebook/auto-messages',
  FACEBOOK_AUTO_RESPONSES_CREATE: '/facebook/auto-responses/create',
  FACEBOOK_AUTO_RESPONSES_EDIT: '/facebook/auto-responses/edit/:id',
  FACEBOOK_AUTO_MESSAGES_RESPONSES_CREATE: '/facebook/auto-messages/create',
  FACEBOOK_AUTO_MESSAGES_RESPONSES_EDIT: '/facebook/auto-messages/edit/:id',
  FACEBOOK_CONVERSATION_TICKERS: '/facebook/conversation-stickers',
  FACEBOOK_LIVESTREAM_SCRIPT: '/facebook/livestream-scripts',
  FACEBOOK_LIVESTREAM_SCRIPT_SINGLE: '/facebook/livestream-scripts/:single',
  FACEBOOK_ORDER_SCRIPT_POST:"/facebook/order-scripts-post",
  FACEBOOK_ORDER_SCRIPT_POST_SINGLE:"/facebook/order-scripts-post/:single",
  FACEBOOK_MESSAGE_CONFIRM_ORDER:"/facebook/message-confirm-order",
  FACEBOOK_PRINT_SETTING: '/facebook/printer-settings',
  TOOL_SHIPPING_TRACKING: '/tools/shipping-tracking',
  TOOL_ADDRESS_SEPARATION: '/tools/address-separation',
  TOOL_ADDRESS_SEPARATION_FILE: '/tools/address-separation/:fileId',
  CONSIGNMENT: '/setting/consignment',
  SETTING: '/setting',
  CUSTOMER: '/partner-management/customer',
  CUSTOMER_SEARCH: '/partner-management/customer/:search',
  ORDER_REFACTOR: '/refactor-orders',
  DELIVERY_MANAGEMENT: '/delivery/management',
  DELIVERY_CREATE: '/delivery/create',
  DELIVERY_OVERVIEW: '/delivery/overview',
  PART_SIGN:'/delivery/part-sign',
  DELIVERY_SIGNPART_ORDER: '/delivery/signpart-order',
  DELIVERY_COD_MANAGEMENT: '/delivery/cod-management',
  FOR_CONTROL_COD: '/delivery/cod-check',
  TOOL_BULK_ORDER: '/tools/bulks-order',
  TOOL_BULK_ORDER_CREATE: '/tools/bulks-order/:fileId',
  CREATE_CUSTOMER: '/partner-management/customer/create',
  EDIT_CUSTOMER: '/partner-management/customer/edit/:id',
  SHIPPING_PARTNER: '/shipping/shipping-partners',
  PRINT_TEMPLATE_SETTING: '/print-template',
  DELIVERY_NOTE: '/setting/delivery',
  BOMB_SHIPMENT: '/bomb-shipment',
  CASHBOOKS: '/cashbooks',
  CASHBOOK_RECEIPTS: '/cashbook/receipts',
  CASHBOOK_PAYMENTS: '/cashbook/payments',
  COST_PRICE: '/cost-price',
  PURCHASE: '/purchases',
  PURCHASE_CREATE: '/purchase/create',
  QUICKLY_PURCHASE_CREATE: '/purchase/quickly-create/:id',
  SUPLIERS: '/partner/suppliers',
  POS: '/pos',
  ECOMMERCES: '/ecommerces',
  GROUP_CUSTOMER: '/setting/group-customer',
  USER: '/users',
  CREATE_USER: '/users/create',
  USER_ROLE: '/user-role',
  USER_ROLE_CREATE: '/user-role/create',
  USER_ROLE_EDIT: '/user-role/edit/:id',
  PAYMENT_METHOD: '/payment-method',
  PRODUCT_GROUP: '/setting/product-category',
  UNITS_MANAGE: '/setting/units-manage',
  SHIPPING_TRACKING: '/shipping-trackings',
  SUPPLIER_MANAGEMENT:'SUPPLIER_MANAGEMENT',
  DELIVERY_OVERVIEW_DASHBOARD:'/delivery-overview',

  CREATE_GIVE_BACK_PRODUCT: envLive ? '' : '/giveback-product/create/:id',
  GIVE_BACK_PRODUCT: envLive ? '' : '/giveback-products',
  INVENTORY_MANAGEMENT:'/warehouse/inventory-management',
  INVENTORY_CREATE:'/warehouse/create',
  INVENTORY_EDIT:'/warehouse/create/:inventoryId',
  WAREHOUSE_TRANSFER_CREATE: '/warehouse/transfer/create',
  PURCHASE_EDIT: '/purchase/edit/:purchaseId',
  PURCHASE_REFUND: '/purchase/refund/:purchaseId',
  PRIVATE_POLICY: '/policy/:page',

  TYPE_RECEIPT:'/setting/type-receipts',
  SETTING_TYPE_PAYMENT:'/setting/type-payment',
  ACCOUNTANT_RECEIPTS: '/accountant/receipts',
  ACCOUNTANT_RECEIPT_CREATE: '/accountant/receipt/create',
  ACCOUNTANT_PAYMENT:'/accountant/payment',
  ACCOUNTANT_PAYMENT_CREATE:'/accountant/payment/create',

  //accountant
  PRICE_ADJUSTMENT: '/accountant/price-adjustment',
  PRICE_ADJUSTMENT_CREATE: '/accountant/price-adjustment/create',
  PRICE_ADJUSTMENT_EDIT: '/accountant/price-adjustment/edit/:productId',


  REPORT_WAREHOUSE: '/report/warehouse',
  REPORT_WAREHOUSE_IMPORT: '/report/warehouse/purchase',
  REPORT_WAREHOUSE_TRANSFER: '/report/warehouse/transfer',
  REPORT_WAREHOUSE_NOTE: '/report/warehouse/note',
  REPORT_WAREHOUSE_IMPORT_EXPORT: '/report/warehouse/import-export',
  REPORT_WAREHOUSE_INVENTORY: '/report/warehouse/inventory',
  REPORT_WAREHOUSE_QUANTITY_LOW_RATE: '/report/warehouse/quantity-low-rate',
  REPORT_SALES: '/report/sales',
  REPORT_SALES_OVERVIEW: '/report/sales/overview',
  REPORT_SALES_EMPLOYEE: '/report/sales/employee',
  REPORT_SALES_ORDER_ORIGIN: '/report/sales/order-origin',
  REPORT_SALES_LOCATION: '/report/sales/location',
  REPORT_SALES_CUSTOMER: '/report/sales/customer',
  REPORT_SALES_PRODUCT_REVENUE: '/report/sales/product-revenue',
  REPORT_SALES_ORDER_REVENUE: '/report/sales/order-revenue',
  REPORT_SALES_SHIPPING_DIFFERENCE: '/report/sales/shipping-difference',
  REPORT_SALES_SHIPPING_DIFFERENCE_EMPLOYEE: '/report/sales/shipping-difference/employee',
  REPORT_SALES_POS_OVERVIEW: '/report/sales/pos',
  FEEDBACK: 'feedback',

  //mini game
  MINI_GAME:'/facebook/minigame',
  NOTIFICATION_MANAGEMENT:'/notifications',

  //add on tools
  ADD_ON_TOOLS:'/tools',
  TOOL_BULK_ORDER_DEDUCT:'/tools/bulks-order-deduct',
  TOOL_BULK_ORDER_DEDUCT_CREATE:'/tools/bulks-order-deduct/:fileId',


  NOT_LICENSED: '/not-licensed',
  NOT_PERMISSION: '/not-permission',

  //header action
  BULK_ORDER:'/bulks-order/:fileId',
  BULK_ORDER_CREATE:'/bulks-order/create',

  BULK_ORDER_DEDUCT:'/bulks-order-deduct/:fileId',
  BULK_ORDER_DEDUCT_CREATE:'/bulks-order-deduct/create',

  SALE_CONFIG:'/setting/sale-config'
}
